<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				input:{},
				hero:[],
				contact_address_content:[],
            }
		},
		computed:{
			mrValidation(){
				return this.$root.config.mrValidation.contact_us
			}
		},
		async mounted() {
			await this.ready();
			setTimeout(() => { 
				SEMICOLON.header.init();
				SEMICOLON.widget.extras(); }, 300)
            this.$set(this.$root, 'page', this);

            this.apiGetStaticHero()
            this.getApi()
		},
        methods: {
        	onSubmit(e) {
				if(e&&e.btnLoading()) return;
				Gen.apirest("/submit-contact",this.input,(err,resp)=>{
					if(e) e.btnUnloading()
					if(err) return swal(err.resp.message,"","warning")
					this.input = {}
					swal(resp.message,"","success")
					this.$router.push({
						name:'Home'
					})
				},"POST")
			},
        	async getStaticHero(id){
	            return (await Gen.apirest('/static-hero', {id:id}, ()=>{})).data
	        },
	        async apiGetStaticHero(){
	            this.hero = await this.getStaticHero(2)
	            setTimeout(()=>{
	            	SEMICOLON.documentOnLoad.init()
	            },500)
	        },
	        getApi() {
				Gen.apirest("/contact-address-content",{},(err,resp)=>{
					if(err) console.log(err)
					this.contact_address_content = resp.data
				})
			},
        },
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element boxed-slider notoppadding">
		    <div class="clearfix">
		        <div class="fslider" data-touch="false" data-easing="easeInQuad">
		            <div class="flexslider">
		                <div class="slider-wrap">
							<div v-if="!isMobile" class="slide" :data-thumb="uploader(hero.img_desktop)">
								<img :src="uploader(hero.img_desktop)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';font-size:'+hero.ash_title_size+'px;'">{{ hero.title }}</h2>
									<p :style="'font-size:'+hero.ash_sub_title_size+'px;color:'+hero.ash_sub_title_color+';font-family:'+hero.ash_sub_title_font_type+';'" v-if="hero.sub_title">{{hero.sub_title}}</p>
								</div>
							</div>
							<div v-else class="slide" :data-thumb="uploader(hero.img_mobile)">
								<img :src="uploader(hero.img_mobile)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';'">{{ hero.title }}</h2>
								</div>
							</div>
						</div>
		            </div>
		        </div>
		    </div>
		</section>
		<section id="content" style="background-color: #000; overflow: visible">
			<div class="content-wrap pt-0 pb-0">
				<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
					<div class="container clearfix">
						<div class="row  justify-content-center dark">
							<div class="col-md-6">
								<div class="wrap_login">
									<h2 class="text-left">{{ web.mn_contact }}</h2>
									<h4 class="text-left" v-html="web.contact_any_question"></h4>
									<VForm @resp="onSubmit">
										<div class="row">
											<div class="col-md-12 mb-2">
												<label class="lbl_field">
													<span class="icon-line2-user"></span>
												</label>
												<div class="form-group">
													<input type="text" name="fullname" onkeydown="return fullNameKey(event)" v-model="input.fullname" v-bind="validation('fullname')" class="frm_custom_sign padleft_form"
													:placeholder="web.cnt_name" required="">
													<LabelError name="fullname"></LabelError>
												</div>
											</div>
											<div class="col-md-12 mb-2">
												<label class="lbl_field">
													<span class="icon-line2-envelope"></span>
												</label>
												<div class="form-group">
													<input type="email" name="email" onkeydown="return emailKey(event)" v-model="input.email" v-bind="validation('email')" class="frm_custom_sign padleft_form"
													:placeholder="web.cnt_email" required="">
													<LabelError name="email"></LabelError>
												</div>
											</div>
											<div class="col-md-12 mb-2">
												<label class="lbl_field">
													<span class="icon-line-paper"></span>
												</label>
												<div class="form-group">
													<input type="text" name="subjek" onkeydown="return fullNameKey(event)" v-model="input.subjek" v-bind="validation('subjek')" class="frm_custom_sign padleft_form"
													:placeholder="web.cnt_subject" required="">
													<LabelError name="subjek"></LabelError>
												</div>
											</div>
											<div class="col-md-12 mb-2">
												<div class="form-group">
													<textarea name="message" v-bind="validation('message')" v-model="input.message" class="frm_custom_sign h_auto" rows="4"
													:placeholder="web.cnt_message" required=""></textarea>
													<LabelError name="message"></LabelError>
												</div>
											</div>
											<div class="col-md-12 text-center">
												<button type="submit" class="btn_line w_100 btn-loading">{{web.btn_submit}} <i class="icon-line-arrow-right"></i></button>
											</div>
										</div>
									</VForm>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row martop_60">
									<div class="col-md-6" v-for="(v,k) in contact_address_content" :key="k">
										<div class="office_address">
											<h3>{{ v.aca_name }}</h3>
											<p v-html="v.aca_address"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>
		</section>
	</div>
</template>